.nb-porsemirror-container .ProseMirror {
  min-height: 12em;
  max-height: 20em;
  overflow: auto;
}

.prosemirror-link-tooltip {
  padding: 4px 8px;
  color: rgb(var(--color-marker));
  background: rgb(var(--color-paper));
  border: 1px solid rgb(var(--color-rim));
  position: absolute;
  z-index: 1;
}

.ProseMirror {
  outline: none;
}