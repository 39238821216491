.nb-table.style-plain .row.table-head {
  border-bottom: 1px solid #ddd;
}

.nb-table.style-striped .row:first-child {
  background: rgb(31, 41, 55);
  color: #fff;
}

.nb-table.style-striped .row:nth-child(odd):not(:first-child) {
  background: rgba(0,0,0,.06);
}