.nb-iframe .iframe-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* grid */
.nb-iframe-editor.is-grid,
.nb-iframe-editor.is-grid .nb-iframe {
  height: 100%;
}