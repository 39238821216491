.article-files-popup .file-info .big-file-icon {
  border: 2px solid #631c29;
  width: 50px;
  height: 60px;
  margin: 20px auto;
  position: relative;
}

.article-files-popup .file-info .big-file-icon:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 14px;
  border-color: rgb(var(--color-paper)) transparent transparent #631c29;
  position: absolute;
  top: -2px;
  right: -2px;
}

.article-files-popup .file-info .big-file-icon .type {
  position: absolute;
  top: 27px;
  right: -5px;
  background: #631c29;
  padding: 5px 5px;
  text-transform: uppercase;
  font-size: .65em;
  color: #fff;
}