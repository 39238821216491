.landing-page .cm-theme {
  height: 100%;
}

.landing-page .cm-editor {
  outline: none;
}


@keyframes border-dance {
  0% {
      background-position: left top, right bottom, left bottom, right top;
  }

  100% {
      background-position: left 10px top, right 10px bottom, left bottom 10px, right top 10px;
  }
}

.active-animation {
  animation: border-dance 5s infinite linear;
  background-image: linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(0deg, black 50%, transparent 50%), linear-gradient(0deg, black 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
}

.landing-page ul,
.landing-page ol {
  list-style-type: initial;
  margin-bottom: 0.5rem;
}

.landing-page ul li, 
.landing-page ol li  {
  margin-left: 1rem;
}
