.nb-back-button {  
  padding: 20px 40px;
}

.nb-back-button a {
  color: #000;
}

.nb-loading-block {
  text-align: center;
  font-size: 1.6em;
  margin: 60px 0;
}

.nb-message-block .image {
  width: 300px;
  margin: 40px auto 20px auto;
}

.nb-message-block .message-box {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.nb-message-block .message-box .title {
  font-weight: bold;
  font-size: 2em;
}

.nb-message-block .message-box .detail {
  border-top: 1px solid #eee;
  margin-top: 20px;
  padding-top: 20px;  
}