.nb-excali-editor .image-container .image svg {
  max-width: 100%;
  height: auto;
  margin: auto;
}

.nb-excali-editor .image-container .image svg text {
  dominant-baseline: text-before-edge;
}

.nb-excali-editor .placeholder svg {
  width: 100%;
  height: auto;
}

/* grid */ 
.nb-excali-editor {
}

.nb-excali-editor.is-grid,
.nb-excali-editor.is-grid .image-container,
.nb-excali-editor.is-grid .image-placeholder,
.nb-excali-editor.is-grid  .image-container .image {
  width: 100%;
  height: 100%;
}

.nb-excali-editor.is-grid .image-container .image svg {
  max-height: 100%;
}