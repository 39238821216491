.home .explore:not(:hover) .admin-button {
  display: none;
}

.home .explore .admin-button {
  cursor: pointer; 
}

.home .explore .featured .title {
  margin-bottom: .4rem;
}