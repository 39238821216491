.nb-icon-button {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 3px;
  background: #fafafa;
}

.svg-icon svg {
  height: 1em;
  vertical-align: -0.125em;
  display: inline-block;
}