html {
  --color-white: 255 255 255;
  --color-black: 0 0 0;

  --color-gray-50: 249 250 251;
  --color-gray-100: 243 244 246;
  --color-gray-200: 229 231 235;
  --color-gray-300: 209 213 219;
  --color-gray-350: 182 188 197;
  --color-gray-400: 156 163 175;
  --color-gray-500: 107 114 128;
  --color-gray-600: 75 85 99;
  --color-gray-700: 55 65 81;
  --color-gray-750: 43 53 68;
  --color-gray-800: 31 41 55;
  --color-gray-850: 24 32 47;
  --color-gray-900: 17 24 39;
  --color-gray-925: 10 16 28;
  --color-gray-950: 3 7 18;

  --color-red-50: 254 242 242;
  --color-red-100: 254 226 226;
  --color-red-200: 254 202 202;
  --color-red-300: 252 165 165;
  --color-red-400: 248 113 113;
  --color-red-500: 239 68 68;
  --color-red-600: 220 38 38;
  --color-red-700: 185 28 28;
  --color-red-800: 153 27 27;
  --color-red-900: 127 29 29;
  --color-red-950: 69 10 10;
  
  --color-sky-50: 240 249 255;
  --color-sky-100 : 224 242 254;
  --color-sky-200 : 186 230 253;
  --color-sky-300 : 125 211 252;
  --color-sky-400 : 56 189 248;
  --color-sky-500 : 14 165 233;
  --color-sky-600 : 2 132 199;
  --color-sky-700 : 3 105 161;
  --color-sky-800 : 7 89 133;
  --color-sky-900 : 12 74 110;
  --color-sky-950 : 8 47 73;
  --color-blue-50 : 239 246 255;
  --color-blue-100: 219 234 254;
  --color-blue-200: 191 219 254;
  --color-blue-300: 147 197 253;
  --color-blue-400: 96 165 250;
  --color-blue-500: 59 130 246;
  --color-blue-600: 37 99 235;
  --color-blue-700: 29 78 216;
  --color-blue-800: 30 64 175;
  --color-blue-900: 30 58 138;
  --color-blue-950: 23 37 84;
  --color-indigo-50 : 238 242 255;
  --color-indigo-100: 224 231 255;
  --color-indigo-200: 199 210 254;
  --color-indigo-300: 165 180 252;
  --color-indigo-400: 129 140 248;
  --color-indigo-500: 99 102 241;
  --color-indigo-600: 79 70 229;
  --color-indigo-700: 67 56 202;
  --color-indigo-800: 55 48 163;
  --color-indigo-900: 49 46 129;
  --color-indigo-950: 30 27 75;
}