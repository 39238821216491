.nb-grid-element .grid-outline {
  position: absolute;
  cursor: move;
  top: -0.5rem;
  left: -0.5rem;
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
}

.nb-grid-element .resizer {
  cursor: se-resize;
}

.nb-grid-elements:not(.dragging) .nb-grid-element {
  pointer-events: auto;
}

.grid-doc-padder {
  padding: 2rem;
}

.casing-floating .grid-doc-padder {
  padding-top: 4rem;
}

.grid-article-extra-top-padding {
  
}