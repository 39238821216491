/* Indicators  */
.nb-element-indicators .save-button {
  position: absolute;
  bottom: 0px;
  right: -30px;
  background: #f0f0f0;
  padding: 4px 5px;
  cursor: pointer;
}

.nb-element-indicators .save-error {
  position: absolute;
  font-size: 12px;
  right: -70px;
  top: 10px;
  background: #fafafa;
  padding: 0 5px;
  cursor: pointer;
}

.nb-element-indicators .saving .dots {
  position: absolute;
  bottom: 5px;
  right:  -10px;
  display: flex;
  flex-direction: column;
}
.nb-element-indicators .saving .dot {
  width: 3px;
  height: 3px;
  display: inline-block;
  border-radius: 50%;
  /*margin-left: 4px;*/
  margin-bottom: 2px;
  transition: background 100ms;
}
.nb-element-indicators .saving .dot.dot1 {
  transition-delay: 1ms;
}
.nb-element-indicators .saving .dot.dot2 {
  transition-delay: 100ms;
}
.nb-element-indicators .saving .dot.dot3 {
  transition-delay: 200ms;
}
.nb-element-indicators .saving .dot.black {
  background: #000;
}
.nb-element-indicators .saving .dot.green {
  background: #0f0;
}