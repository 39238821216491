
.katex-popup .invalid { 
  background: #eee;
  padding: 20px 0;
  margin-bottom: 10px;

  text-align: center;
  text-decoration: underline;
}

.katex-popup textarea {
  margin: 0;
}