.nb-popup .popup-dialog .popup-title-bar {
  background: var(--popup-title-bar-bg);
}

/* loader */
.nb-popup-loader-bar { 
  animation: nb-bar-frames 1s linear infinite;
}

@keyframes nb-bar-frames {
  0% { left: 0%; }
  50% { left: 20%; }
  100% { left: 0%; }
}