.nb-box-runner .output-container {
  white-space: pre;
  overflow: auto;
  min-height: 10px;
}

.nb-box-runner .output {
  padding: 4px 15px;
  overflow: auto;
}

.nb-box-runner .output .output-data {
  white-space: pre-wrap;
  word-break: break-all;
}



.nb-box-runner .output .output-data.stderr,
.nb-box-runner .output .output-data.error {
  color: #e00;
}

.nb-box-runner .output .stdin {
  color: rgb(var(--color-blue-800));
  
}
/* is grid */