.upload-image-popup .image-section {
  text-align: center;
  position: relative;
}

.upload-image-popup .image-container {
  padding: 5px;
  border: 1px solid #ddd;
  display: inline-block;
}

.upload-image-popup .image-section .dnd-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  background: #000;
  color: #fff;
  opacity: .7;
  padding: 2px 5px;
}

.upload-image-popup .image-section.dragging .image-container {
  border: 1px solid #ddd;
}


.upload-image-popup .action-section .progressbar1 {
  width: 100%;
  height: 3px;
  background-color: #dadada;
}

.upload-image-popup .action-section .progressbar2 {
  width: 100%;
  height: 3px;
  background-color: #1EAEDB;
  margin-top: -3px;
}

.upload-image-popup img {
  display: block;
}