.upload-popup .dnd-box {
  border: 1px;
  text-align: center;
  padding: 80px 0;
  border: 1px dashed #000;
}

.upload-popup .dnd-box.dragging {
  background: #eee;
}

.upload-popup .dnd-or {
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.upload-popup .selection .buttons {
  text-align: center;
}

.upload-popup .confirm .progressbar1 {
  width: 100%;
  height: 3px;
  background-color: #dadada;
}

.upload-popup .confirm .progressbar2 {
  width: 100%;
  height: 3px;
  background-color: #1EAEDB;
  margin-top: -3px;
}