.nb-dots-loader {
  display: inline-block;
}

.nb-dots-loader .dot {
  animation: loadingDotAnimate 1000ms linear infinite;
  display: inline-block;
  vertical-align: top;
}

.nb-dots-loader .dot1 {
  animation-delay: 0ms;
}
.nb-dots-loader .dot2 {
  animation-delay: 150ms;
}
.nb-dots-loader .dot3 {
  animation-delay: 300ms;
}

@keyframes loadingDotAnimate {
  0% { opacity: 1; }
  25% { opacity: .1; }
  50% { opacity: 1; }
}