.nb-table .row .cell {
  border: 1px solid #ffffff00;
}

.nb-table .row .cell.selected {
  background: #ddddff33;
}

.nb-table:not(.focused) .table .row .cell.active {
  border: 1px solid #333333;
}
.nb-table.focused .table .row .cell.active {
  border: 1px solid #3333ff;
}

.nb-table .row .cell input {
  border: none;
  margin: 0;
  padding: 0;
}

.nb-table .row .cell .cell-input-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.nb-table .row .cell textarea {
  padding: 0;
  border: 0;
  margin: 0;
  background: none;
  resize: none;
  padding: 1px 10px;
  outline: none;
}

.nb-table .row .cell .suffix,
.nb-table .row .cell .prefix {
  white-space: pre;
}

.nb-table .title-display {
  display: flex;
}

.nb-table .title-display .title {
  flex-grow: 1;
}
