/* Fixes */
.nb-code .cm-editor { outline: none;  }
.nb-code .cm-editor input { width: auto; }
.nb-code .cm-scroller { padding: 0 0; }
.nb-code .cm-content { padding: 0.5rem 0.5rem; }

/* is grid */
.nb-code.is-full-height .codemirror-container {
  overflow: hidden;
  flex-grow: 1;
}

.nb-code.is-full-height .codemirror-instance,
.nb-code.is-full-height .cm-theme,
.nb-code.is-full-height .cm-theme-light,
.nb-code.is-full-height .cm-editor {
  height: 100%;
}

.nb-code ::-webkit-scrollbar-thumb {
  border-radius: 0px;
}